// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'notifiable_type',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'notification_type',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'push_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'scheduled_time',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'title',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'has_read',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
